<template>
  <v-container>
    <div align="center">
      <v-card max-width="500" class="mt-5" rounded="xl" elevation="4">
        <v-card-title class="d-flex flex-column">
          <v-icon color="success" x-large>{{ mdiCheckCircle }}</v-icon>
          <div class="mt-4">ทำรายการเรียบร้อย</div>
           <v-btn
            color="primary"
            href="https://app-apsth.com"
          >
            Back to Home
          </v-btn>
        </v-card-title>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import { mdiCheckCircle } from '@mdi/js'
export default {
  setup() {
    return {
      mdiCheckCircle,
    }
  },
}
</script>
